import breadcrumbComp from "../../common/breadcrumb-comp.vue";
// import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import shippingService from "./shipping-service";
import FilterComp from "../../common/filter-comp.vue";
import router from "../../../router/router";
export default {
  data() {
    return {
      lpnText: "",
      lpnNum: "",
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      soSearchKey: "",
      disableSearch: false,
      poSearchKey: "",
      selectedProcess: "",
      processTypeData: [],
      disableProcessType: false,
      selectedProject: "",
      projectData: [],
      disableProject: true,
      selectedBusiness: "",
      businessData: [],
      disableBusiness: true,
      selectedCountry: "",
      countryData: [],
      disableCountry: true,
      disableRun: false,
      totalRecords: 0,
      pickingData: [],
      pickingDataTemp: [],
      searchFilter: "",
      showSOSearch: false,
      showPOSearch: false,
      intransitFlag: false,
      lpnMsgUser: "",
      arrayData: [],
      uniqueOrderType: [],
      uniqueBusiness: [],
      uniqueCarrier: [],
      uniqueStatus: [],
      pickingHeaders: [
        { text: "Project/Business", align: "start", value: "projBusDetails", class: "primary customwhite--text" },
        { text: "Order", value: "OrderNo", class: "primary customwhite--text" },
        { text: "Customer ID", value: "Customer_ID", class: "primary customwhite--text" },
        { text: "Order Type", value: "Order_Type", class: "primary customwhite--text" },
        { text: "Ordered Date", value: "OrderDate", class: "primary customwhite--text" },
        { text: "Required Date", value: "ReqDate", class: "primary customwhite--text" },
        { text: "Carrier", value: "SLevel", class: "primary customwhite--text" },
        { text: "Assigned To", value: "Pickers", class: "primary customwhite--text" },
        { text: "Status", value: "Status", class: "primary customwhite--text" },
        { text: "Action", value: "Action", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getUserPickedLine();
    this.getProcessData();
    this.getSearch();
    this.breadcrumbLPNData();
  },
  async mounted() {
    router.afterEach((to) => {
      if (!to.path.includes("fulfillment-picking-queue-details") && !to.path.includes("fulfillment-picking-queue")) {
        EncryptUtility.localStorageRemoveItem("FPProcessType");
        EncryptUtility.localStorageRemoveItem("FPProcessTypeData");
        EncryptUtility.localStorageRemoveItem("FPProject");
        EncryptUtility.localStorageRemoveItem("FPProjectData");
        EncryptUtility.localStorageRemoveItem("FPBusiness");
        EncryptUtility.localStorageRemoveItem("FPBusinessData");
        EncryptUtility.localStorageRemoveItem("FPCountry");
        EncryptUtility.localStorageRemoveItem("FPCountryData");
      }
    });
  },
  methods: {
    //Reset Function
    async resetFunction() {
      this.soSearchKey = "";
      this.disableSearch = false;
      this.poSearchKey = "";
      this.selectedProcess = "";
      this.processTypeData = [];
      this.disableProcessType = false;
      this.selectedProject = "";
      this.projectData = [];
      this.disableProject = true;
      this.selectedBusiness = "";
      this.businessData = [];
      this.disableBusiness = true;
      this.selectedCountry = "";
      this.countryData = [];
      this.disableCountry = true;
      this.disableRun = false;
      this.totalRecords = 0;
      this.pickingData = [];
      this.pickingDataTemp = [];
      this.searchFilter = "";
      this.showSOSearch = false;
      this.showPOSearch = false;
      this.uniqueOrderType = [];
      this.uniqueBusiness = [];
      this.uniqueCarrier = [];
      this.uniqueStatus = [];
      this.removeLocalStorage();
      this.getUserPickedLine();
      this.getProcessData();
      this.breadcrumbLPNData();
    },
    //Show LPN Data on breadcrumb
    async breadcrumbLPNData() {
      let lpnStatus = await AddFavourite.getLPNDetails(this.userId);
      this.lpnText = lpnStatus[0]?.lpn_info;
      this.lpnNum = lpnStatus[0]?.lpn;
      this.intransitFlag = lpnStatus[0]?.intransit;
      this.lpnMsgUser = lpnStatus[0]?.additionalmsg;
      if (this.intransitFlag) {
        let Alert = {
          type: "error",
          isShow: true,
          message: this.lpnMsgUser,
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Process Data
    async getUserPickedLine() {
      let data = await shippingService.userPickedLineData("get", this.userId);
      if (data?.message !== "NA") {
        if (data[0]?.process_type == "SO") {
          this.$router.push({
            name: "fulfillmentPickingQueueDetails",
            params: {
              id: btoa(data[0]?.id.toString()),
              processType: btoa("SO"),
            },
          });
        } else if (data[0]?.process_type == "PO") {
          this.poSearchKey = data[0].id;
          this.$router.push({
            name: "fulfillmentPickingQueueDetails",
            params: {
              id: btoa(data[0]?.id.toString()),
              processType: btoa("PO"),
            },
          });
        }
      }
    },
    //Loading Local Storage items for search persistance
    getSearch() {
      if (EncryptUtility.localStorageDecrypt("FPProcessType") !== null && EncryptUtility.localStorageDecrypt("FPProcessType") !== undefined) {
        this.selectedProcess = EncryptUtility.localStorageDecrypt("FPProcessType");
        this.processTypeData = JSON.parse(EncryptUtility.localStorageDecrypt("FPProcessTypeData"));
        this.getProjectData();
        if (EncryptUtility.localStorageDecrypt("FPProject") !== null && EncryptUtility.localStorageDecrypt("FPProject") !== undefined) {
          this.selectedProject = parseInt(EncryptUtility.localStorageDecrypt("FPProject"));
          this.projectData = JSON.parse(EncryptUtility.localStorageDecrypt("FPProjectData"));
          this.getBusinessData();
          if (EncryptUtility.localStorageDecrypt("FPBusiness") !== null && EncryptUtility.localStorageDecrypt("FPBusiness") !== undefined) {
            this.selectedBusiness = parseInt(EncryptUtility.localStorageDecrypt("FPBusiness"));
            this.businessData = JSON.parse(EncryptUtility.localStorageDecrypt("FPBusinessData"));
            this.getCountryData();
            if (EncryptUtility.localStorageDecrypt("FPCountry") !== null && EncryptUtility.localStorageDecrypt("FPCountry") !== undefined) {
              this.selectedCountry = EncryptUtility.localStorageDecrypt("FPCountry");
              this.countryData = JSON.parse(EncryptUtility.localStorageDecrypt("FPCountryData"));
              this.runReport();
              this.disableProcessType = true;
              this.disableProject = true;
              this.disableBusiness = true;
              this.disableCountry = true;
            }
          }
        }
      }
    },
    //Process Data
    async getProcessData() {
      let obj = {
        UserID: parseInt(this.userId),
        ddl_pass: "1",
        process_list: "",
        projkey_list: "",
        bukey_list: "",
      };
      let showMessage = false;
      let data = await shippingService.getPickingQueueDrop("post", obj, showMessage);
      if (data.message !== "NA") {
        this.processTypeData = data;
        EncryptUtility.localStorageEncrypt("FPProcessTypeData", JSON.stringify(this.processTypeData));
      }
    },
    //Get Project Data
    async getProjectData() {
      let processList = [];
      processList.push(this.selectedProcess);
      let obj = {
        UserID: parseInt(this.userId),
        ddl_pass: "2",
        process_list: JSON.stringify(processList),
        projkey_list: "",
        bukey_list: "",
      };
      let showMessage = false;
      let data = await shippingService.getPickingQueueDrop("post", obj, showMessage);
      if (data.message !== "NA") {
        this.projectData = data;
        this.disableProject = false;
        this.disableProcessType = true;
        EncryptUtility.localStorageEncrypt("FPProcessType", this.selectedProcess);
        EncryptUtility.localStorageEncrypt("FPProjectData", JSON.stringify(this.projectData));
      }
    },
    //Get Business Data
    async getBusinessData() {
      let processList = [];
      processList.push(this.selectedProcess);
      let projectList = [];
      projectList.push(this.selectedProject);
      let obj = {
        UserID: parseInt(this.userId),
        ddl_pass: "3",
        process_list: JSON.stringify(processList),
        projkey_list: JSON.stringify(projectList),
        bukey_list: "",
      };
      let showMessage = false;
      let data = await shippingService.getPickingQueueDrop("post", obj, showMessage);
      if (data.message !== "NA") {
        this.businessData = data;
        this.disableBusiness = false;
        this.disableProject = true;
        EncryptUtility.localStorageEncrypt("FPProject", this.selectedProject.toString());
        EncryptUtility.localStorageEncrypt("FPBusinessData", JSON.stringify(this.businessData));
      }
    },
    //Get Country Data
    async getCountryData() {
      let processList = [];
      processList.push(this.selectedProcess);
      let projectList = [];
      projectList.push(this.selectedProject);
      let businessList = [];
      businessList.push(this.selectedBusiness);
      let obj = {
        UserID: parseInt(this.userId),
        ddl_pass: "6",
        process_list: JSON.stringify(processList),
        projkey_list: JSON.stringify(projectList),
        bukey_list: JSON.stringify(businessList),
      };
      let showMessage = false;
      let data = await shippingService.getPickingQueueDrop("post", obj, showMessage);
      if (data.message !== "NA") {
        this.countryData = data;
        this.disableCountry = false;
        this.disableBusiness = true;
        EncryptUtility.localStorageEncrypt("FPBusiness", this.selectedBusiness.toString());
        EncryptUtility.localStorageEncrypt("FPCountryData", JSON.stringify(this.countryData));
      }
    },
    //Run button click event
    async runReport() {
      let processList = [];
      processList.push(this.selectedProcess);
      let projectList = [];
      projectList.push(this.selectedProject);
      let businessList = [];
      businessList.push(this.selectedBusiness);
      let countryList = [];
      countryList.push(this.selectedCountry);
      let jsonObject = JSON.stringify({
        process_list: processList,
        projkey_list: projectList,
        bukey_list: businessList,
        country_list: countryList,
      });
      let obj = {
        user_ID: parseInt(this.userId),
        json: jsonObject,
      };
      let showMessage = false;
      let data = await shippingService.pickingQueueSearch("post", obj, showMessage);
      if (data.message !== "NA" && data.message !== null) {
        this.pickingData = data;
        this.pickingData?.forEach((element) => {
          element.Customer_ID.replace(" ", "<br />");
        });
        this.pickingDataTemp = this.pickingData;
        this.totalRecords = data.length;
        this.disableProcessType = true;
        this.disableProject = true;
        this.disableBusiness = true;
        this.disableCountry = true;
        EncryptUtility.localStorageEncrypt("FPCountry", this.selectedCountry);
        this.filterArrayData();
      } else {
        this.resetSearch();
        this.pickingData = [];
        this.pickingDataTemp = [];
        this.totalRecords = 0;
        let Alert = {
          type: "error",
          isShow: true,
          message: "No Records based on selected criteria.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Search SO input
    searchSO() {
      this.soSearchKey = this.soSearchKey?.toUpperCase();
      this.showSOSearch = true;
      this.showPOSearch = false;
      this.poSearchKey = "";
    },
    //SO search
    async onSOClickSearch() {
      let obj = {
        user_id: parseInt(this.userId),
        process_type: "SO",
        order_no: this.soSearchKey,
      };
      let showMessage = false;
      let data = await shippingService.pickQuickSearch("post", obj, showMessage);
      if (data.message !== "NA") {
        this.$router.push({
          name: "fulfillmentPickingQueueDetails",
          params: {
            id: btoa(data[0].so_id.toString()),
            processType: btoa("SO"),
          },
        });
      }
    },
    //Search PO input
    searchPO() {
      this.poSearchKey = this.poSearchKey?.toUpperCase();
      this.showPOSearch = true;
      this.showSOSearch = false;
      this.soSearchKey = "";
    },
    //PO search
    async onPOClickSearch() {
      let obj = {
        user_id: parseInt(this.userId),
        process_type: "PO",
        order_no: this.poSearchKey,
      };
      let showMessage = false;
      let data = await shippingService.pickQuickSearch("post", obj, showMessage);
      if (data.message !== "NA") {
        this.$router.push({
          name: "fulfillmentPickingQueueDetails",
          params: {
            id: btoa(data[0].po_id.toString()),
            processType: btoa("PO"),
          },
        });
      }
    },
    //Pick button click event
    routerFunction(item) {
      let id = 0;
      id = item.Type == "PO" ? item.po_id : item.so_id;
      this.$router.push({
        name: "fulfillmentPickingQueueDetails",
        params: {
          id: btoa(id.toString()),
          processType: btoa(item.Type.toString()),
        },
      });
    },
    //Remove local storage
    removeLocalStorage() {
      EncryptUtility.localStorageRemoveItem("FPProcessType");
      EncryptUtility.localStorageRemoveItem("FPProcessTypeData");
      EncryptUtility.localStorageRemoveItem("FPProject");
      EncryptUtility.localStorageRemoveItem("FPProjectData");
      EncryptUtility.localStorageRemoveItem("FPBusiness");
      EncryptUtility.localStorageRemoveItem("FPBusinessData");
      EncryptUtility.localStorageRemoveItem("FPCountry");
      EncryptUtility.localStorageRemoveItem("FPCountryData");
    },
    //Reset Data for search
    resetSearch() {
      this.soSearchKey = "";
      this.disableSearch = false;
      this.poSearchKey = "";
      this.selectedProcess = "";
      this.processTypeData = [];
      this.disableProcessType = false;
      this.selectedProject = "";
      this.projectData = [];
      this.disableProject = true;
      this.selectedBusiness = "";
      this.businessData = [];
      this.disableBusiness = true;
      this.selectedCountry = "";
      this.countryData = [];
      this.disableCountry = true;
      this.disableRun = false;
      this.totalRecords = 0;
      this.pickingData = [];
      this.pickingDataTemp = [];
      this.searchFilter = "";
      this.showSOSearch = false;
      this.showPOSearch = false;
      this.removeLocalStorage();
      this.getProcessData();
      this.breadcrumbLPNData();
    },
    //Filter Array Data
    filterArrayData() {
      this.uniqueOrderType = [...new Map(this.pickingData.map((item) => [item["Order_Type"], item.Order_Type])).values()];
      this.uniqueBusiness = [...new Map(this.pickingData.map((item) => [item["Business"], item.Business])).values()];
      this.uniqueCarrier = [...new Map(this.pickingData.map((item) => [item["SLevel"], item.SLevel])).values()];
      this.uniqueStatus = [...new Map(this.pickingData.map((item) => [item["Status"], item.Status])).values()];
      this.uniqueOrderType = this.uniqueOrderType.filter((obj) => obj !== "");
      this.uniqueBusiness = this.uniqueBusiness.filter((obj) => obj !== "");
      this.uniqueCarrier = this.uniqueCarrier.filter((obj) => obj !== "");
      this.uniqueStatus = this.uniqueStatus.filter((obj) => obj !== "");
      this.arrayData = [
        { id: 1, label: "Order Type", array: this.uniqueOrderType },
        { id: 2, label: "Business", array: this.uniqueBusiness },
        { id: 3, label: "Carrier", array: this.uniqueCarrier },
        { id: 4, label: "Status", array: this.uniqueStatus },
      ];
    },
    async filteredValues(value, type) {
      const filters = {
        0: () => this.pickingDataTemp,
        1: (value) => this.pickingDataTemp.filter((element) => element.Order_Type === value),
        2: (value) => this.pickingDataTemp.filter((element) => element.Business === value),
        3: (value) => this.pickingDataTemp.filter((element) => element.SLevel === value),
        4: (value) => this.pickingDataTemp.filter((element) => element.Status === value),
      };
      this.filterValueUpdate(filters[type](value));
    },
    filterValueUpdate(data) {
      this.totalRecords = data.length;
      this.pickingData = data;
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    FilterComp,
  },
};
